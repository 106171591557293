/* Carrusel.css */
.header {
  transition: transform 0.3s ease-in-out;
  z-index: 50; /* Asegúrate de que el z-index sea alto */
}

.carrusel-container {
  width: 100%;
  margin: auto;
}

.carrusel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.carrusel-contenido {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carrusel-imagen {
  object-fit: cover;
  width: 200px;
  height: 200px;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev, .slick-next {
  background-color: #000; /* Ajusta el color de fondo de las flechas */
  color: #fff; /* Ajusta el color de las flechas */
  border-radius: 50%; /* Haz que las flechas sean circulares */
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev:hover, .slick-next:hover {
  background-color: #555; /* Cambia el color de fondo al pasar el mouse */
}

.slick-prev {
  left: -35px; /* Ajusta la posición horizontal de la flecha izquierda */
}

.slick-next {
  right: -35px; /* Ajusta la posición horizontal de la flecha derecha */
}
